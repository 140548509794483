import styled, { css } from "styled-components";
import { Icon } from "@diana-ui/icon";
import { BodyHighlight } from "@diana-ui/typography";

export const SLIDE_ANIMATION_DURATION_MS = 200;

export const Header = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spaceUnit.xl} ${theme.spaceUnit.lg};

    &.disabled {
      cursor: default;
    }
  `
);

export const HeaderTitle = styled(BodyHighlight)``;

export const HeaderIcon = styled(Icon)(
  () => css`
    flex-shrink: 1;
    transition: 0.2s transform;
    transform: rotateZ(0deg);

    &.expanded {
      transform: rotateZ(-180deg);
    }
  `
);

/**
 * Component's Panel
 */
export const Panel = styled.div<{ canAnimate: boolean; headerHeight: number }>(
  ({ canAnimate, headerHeight }) => css`
    max-height: ${canAnimate ? "initial" : `${headerHeight}px`};
    overflow: hidden;

    &.disabled,
    &.empty {
      ${Header} {
        cursor: default;
      }
      ${HeaderIcon} {
        display: none;
      }
    }
  `
);

export const Body = styled.div(
  () => css`
    display: flex;
  `
);

export const BodyWrapper = styled.div<{ finalBodyHeight?: number | string; isCollapsing: boolean }>(
  ({ finalBodyHeight, isCollapsing }) => css`
    ${{
      height: finalBodyHeight,
      maxHeight: finalBodyHeight === 0 && !isCollapsing ? 0 : undefined
    }}

    overflow: auto;
    transition: height ${SLIDE_ANIMATION_DURATION_MS}ms ease-out;

    // hide scrollbar
    // Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
  `
);
