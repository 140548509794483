import React from "react";
import { Pie } from "@vx/shape";
import { Group } from "@vx/group";
import { Spring, config } from "react-spring/renderprops";
import { ParentSize } from "@vx/responsive";
import { DefaultTheme, useTheme } from "styled-components";

export interface IProps {
  size?: number;
  thickness?: number;
  backgroundColor?: {
    fill?: string;
    border?: string;
  };
  foregroundColor?: {
    fill?: string;
    border?: string;
  };
  targetColor?: {
    fill?: string;
    border?: string;
  };
  value: number;
  target?: number;
  cornerRadius?: number;
  targetWidth?: number;
  delay?: number;
}

export type IDonutProps = IProps & JSX.IntrinsicElements["div"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const valueAccessor = (d: any) => d.value;
const Donut: React.FC<IDonutProps> = ({
  size,
  value,
  target,
  children,
  thickness,
  targetColor,
  backgroundColor,
  foregroundColor,
  className,
  targetWidth = 4,
  cornerRadius,
  delay
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme = useTheme() as DefaultTheme | any;
  const data = [{ label: "Total", value }];

  const innerDonut = (containerSize: number, radius: number) => {
    return (
      <svg className={className} width={containerSize} height={containerSize}>
        <Group top={radius} left={radius}>
          <Pie
            data={[{ value: 100 }]}
            pieValue={valueAccessor}
            outerRadius={radius}
            innerRadius={radius - (thickness || 20)}
            cornerRadius={0}
            padAngle={0}
          >
            {pie =>
              pie.arcs.map(arc => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const arcPath: any = pie.path(arc);
                return (
                  <g key="arc-background">
                    <path
                      d={arcPath}
                      fill={
                        (backgroundColor && backgroundColor.fill) ||
                        theme.colors.grey.grey25
                      }
                      stroke={
                        (backgroundColor && backgroundColor.border) || "none"
                      }
                    />
                  </g>
                );
              })
            }
          </Pie>
        </Group>
        <Group top={radius} left={radius}>
          <Pie
            data={data}
            pieValue={valueAccessor}
            outerRadius={radius}
            innerRadius={radius - (thickness || 20)}
            cornerRadius={
              cornerRadius === null || cornerRadius === undefined
                ? 5
                : cornerRadius
            }
            padAngle={0}
          >
            {pie =>
              pie.arcs.map(arc => (
                <Spring
                  key="spring-arc-foreground"
                  config={
                    delay ? { ...config.molasses, delay } : config.molasses
                  }
                  from={{ value: 0 }}
                  to={{ value: (value * Math.PI * 2) / 100 }}
                >
                  {props => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const arcPath: any = pie.path({
                      ...arc,
                      endAngle: props.value
                    });
                    return (
                      <g key={`donut-${arc.data.label}`}>
                        <path
                          d={arcPath}
                          fill={
                            (foregroundColor && foregroundColor.fill) ||
                            "steelblue"
                          }
                          stroke={
                            (foregroundColor && foregroundColor.border) ||
                            "none"
                          }
                        />
                      </g>
                    );
                  }}
                </Spring>
              ))
            }
          </Pie>
        </Group>
        {target && (
          <Group top={radius} left={radius}>
            <Pie
              data={[{ value: targetWidth }]}
              pieValue={valueAccessor}
              outerRadius={radius}
              innerRadius={radius - (thickness || 20)}
              cornerRadius={thickness || 20}
              padAngle={0}
            >
              {pie =>
                pie.arcs.map(arc => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const arcPath: any = pie.path({
                    ...arc,
                    startAngle: ((target - targetWidth) * Math.PI * 2) / 100,
                    endAngle: ((target + targetWidth) * Math.PI * 2) / 100
                  });
                  return (
                    <g key="arc-back-target">
                      <path
                        d={arcPath}
                        fill={(targetColor && targetColor.fill) || "indianred"}
                        stroke={(targetColor && targetColor.border) || "none"}
                      />
                    </g>
                  );
                })
              }
            </Pie>
          </Group>
        )}
        <foreignObject x={0} y={0} width={size} height={size}>
          {children}
        </foreignObject>
      </svg>
    );
  };
  return size ? (
    innerDonut(size, size / 2)
  ) : (
    <ParentSize>
      {parent => {
        const radius = Math.min(parent.width, parent.height);
        return innerDonut(radius, radius / 2);
      }}
    </ParentSize>
  );
};

export default Donut;
