import styled, { css } from "styled-components";
import { Body, SectionTitle } from "@ddm-design-system/typography";

export const StyledKegWrapper = styled.div(
  ({ theme }) => css`
    align-items: center;
    border: 1px solid ${theme.colors.grey.grey50};
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 132px;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    width: 62px;

    &.error {
      border-color: ${theme.colors.alert.alert100};
    }

    &.tank {
      border-radius: 270px;
      height: auto;
      justify-content: center;
      min-height: 132px;
      min-width: 62px;
      width: auto;
    }
  `
);
export const StyledKegLabel = styled.span(
  ({ theme }) => css`
    margin-bottom: ${theme.spaceUnit.sm};
    z-index: 1;
  `
);
export const StyledSectionTitle = styled(SectionTitle)(() => css``);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const common = (theme: any) => css`
  border-radius: 50%;
  height: 38px;
  margin-bottom: ${theme.spaceUnit.sm};
  width: 38px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledBody = styled(Body)<{ logoFallback: any }>(
  ({ theme, logoFallback }) => css`
    ${common(theme)}
    align-items: center;
    background-color: ${logoFallback?.color || theme.colors.grey.grey50};
    display: flex;
    justify-content: center;
  `
);

export const StyledLogoImg = styled.img(
  ({ theme }) => css`
    ${common(theme)}
  `
);

export const StyledBeverage = styled.div<{ height: number }>(
  ({ theme, height }) => css`
    background-color: ${theme.colors.grey.greenish};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    transition: height 1.5s;
    height: ${height}%;
  `
);
export const StyledBeverageBorder = styled.div(
  ({ theme }) => css`
    background-color: transparent;
    border: 3px solid ${theme.colors.white};
    border-radius: 40px;
    bottom: 0;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
  `
);
