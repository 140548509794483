import React, { useCallback, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useIsMobile, useWindowSize } from "@ddm-design-system/hooks";
import { IconNames } from "@ddm-design-system/icon";
import { IModalProps } from "@diana-ui/modal";
import * as ModalTooltipStyles from "./ModalTooltip.style";

export interface IProps {
  icon?: IconNames;
  iconClassName?: string;
  iconColor?: string;
  title: string;
  titleIcon?: string | JSX.Element;
  onAfterOpen?: IModalProps["onAfterOpen"];
}

export type IModalTooltipProps = IProps & JSX.IntrinsicElements["div"];

const ModalTooltip: React.FC<IModalTooltipProps> = ({
  children,
  className,
  icon,
  iconClassName,
  iconColor,
  title,
  titleIcon,
  onAfterOpen
}) => {
  const theme = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const isMobile = useIsMobile();

  const handleClick = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const customTitleIcon =
    typeof titleIcon === "string" ? (
      <ModalTooltipStyles.StyledTitleIcon name={titleIcon as IconNames} />
    ) : (
      titleIcon
    );

  return (
    <>
      <ModalTooltipStyles.StyledTertiaryButton
        className={iconClassName}
        icon={icon ?? "Info"}
        iconColor={iconColor ?? theme.colors.grey.grey50}
        onClick={handleClick}
      />
      <ModalTooltipStyles.StyledModal
        isOpen={visible}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={() => setVisible(false)}
        className={className}
        onAfterOpen={onAfterOpen}
        isBottomSheet={false}
      >
        <ModalTooltipStyles.StyledModalHeader
          headerTitle={title}
          icon={customTitleIcon}
          showCloseButton={isMobile}
          onClose={() => setVisible(false)}
        />
        <ModalTooltipStyles.StyledModalBody>
          {children}
        </ModalTooltipStyles.StyledModalBody>
      </ModalTooltipStyles.StyledModal>
    </>
  );
};

export { ModalTooltipStyles };

export default ModalTooltip;
