import React from "react";
import styled, { css } from "styled-components";
import { BaseButton } from "@diana-ui/button";
import { INavButtonProps } from "./NavButton";
import * as NavButtonMobileStyles from "./NavButtonMobile.style";

export type INavButtonMobileProps = INavButtonProps;

const NavButtonMobile = styled(BaseButton).attrs<INavButtonMobileProps>(
  ({ className, selected, disabled, icon, children }) => ({
    className: [className, selected && "selected", disabled && "disabled"]
      .filter(Boolean)
      .join(" "),
    renderLeftIcon: () => <NavButtonMobileStyles.StyledIcon name={icon} />,
    children: (
      <NavButtonMobileStyles.StyledLabelMedium>
        {children}
      </NavButtonMobileStyles.StyledLabelMedium>
    )
  })
)(
  ({ theme }) => css`
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.selected {
      color: ${theme.colors.black};

      ${NavButtonMobileStyles.StyledIcon} {
        fill: ${theme.colors.grey.grey50};
        color: ${theme.colors.grey.grey50};
      }

      ${NavButtonMobileStyles.StyledLabelMedium} {
        color: ${theme.colors.black};
      }
    }

    &.disabled {
      cursor: default;

      ${NavButtonMobileStyles.StyledIcon} {
        fill: ${theme.colors.grey.grey50};
        color: ${theme.colors.grey.grey50};
      }

      ${NavButtonMobileStyles.StyledLabelMedium} {
        color: ${theme.colors.grey.grey50};
      }
    }
  `
);

export { NavButtonMobileStyles };

export default NavButtonMobile;
