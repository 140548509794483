import React, { forwardRef } from "react";
import {
  IExpandablePanelProps as IBaseExpandablePanelProps,
  ExpandablePanelStyles as BaseExpandablePanelStyles
} from "@diana-ui/expandable-panel";
import * as ExpandablePanelStyles from "./ExpandablePanel.style";

export interface IProps {
  renderHeaderDetails?: (isExpanded: boolean) => React.ReactNode;
  renderSubHeader?: (isExpanded: boolean) => React.ReactNode;
  subtitle?: string;
  title: string;
}

export type IExpandablePanelProps = IProps & IBaseExpandablePanelProps;

const ExpandablePanel = forwardRef<HTMLDivElement, IExpandablePanelProps>(
  (
    { renderHeaderDetails, renderSubHeader, subtitle, title, ...props },
    ref
  ) => {
    const header = (isExpanded: boolean) => (
      <ExpandablePanelStyles.Header>
        <ExpandablePanelStyles.HeaderBody>
          <ExpandablePanelStyles.HeaderTitleWrapper>
            <ExpandablePanelStyles.StyledHeaderTitle>
              {title}
            </ExpandablePanelStyles.StyledHeaderTitle>
            <ExpandablePanelStyles.StyledSubitle>
              {subtitle}
            </ExpandablePanelStyles.StyledSubitle>
          </ExpandablePanelStyles.HeaderTitleWrapper>
          {renderHeaderDetails?.(isExpanded)}
        </ExpandablePanelStyles.HeaderBody>
        {renderSubHeader?.(isExpanded)}
      </ExpandablePanelStyles.Header>
    );

    return (
      <ExpandablePanelStyles.StyledBaseExandablePanel
        {...props}
        ref={ref}
        header={header}
      />
    );
  }
);

export { ExpandablePanelStyles, BaseExpandablePanelStyles };

export default ExpandablePanel;
