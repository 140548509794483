import React, { useRef } from "react";
import { IPopoverRef, Direction } from "@ddm-design-system/popover";
import { IconNames } from "@ddm-design-system/icon";
import * as MessageTooltipStyles from "./MessageTooltip.style";

const ICON_SIZE_PX = 12;

export interface IMessageTooltipProps {
  direction?: Direction;
  icon?: IconNames;
  renderHeader: () => JSX.Element;
  zIndex?: number;
}

const MessageTooltip: React.FC<IMessageTooltipProps> = ({
  children,
  direction = "top",
  icon,
  renderHeader,
  zIndex
}) => {
  const ref = useRef<IPopoverRef>(null);

  return (
    <MessageTooltipStyles.StyledPopover
      centered
      direction={direction}
      renderHeader={renderHeader}
      showOnHover
      ref={ref}
      zIndex={zIndex}
    >
      <>
        {icon && (
          <MessageTooltipStyles.StyledIcon name={icon} size={ICON_SIZE_PX} />
        )}
        <MessageTooltipStyles.StyledBody>
          {children}
        </MessageTooltipStyles.StyledBody>
      </>
    </MessageTooltipStyles.StyledPopover>
  );
};

export { MessageTooltipStyles };

export default MessageTooltip;
