import styled, { css } from "styled-components";

/**
 * Component's Section
 */
export const Section = styled.section``;

export const Body = styled.div``;

export const ArrowsContainerLeft = styled.div``;

export const ArrowsContainerRight = styled.div``;

export const DummyDiv = styled.div<{
  dummyDivWidth: number;
}>`
  width: ${props => props.dummyDivWidth}px;
`;

export const ScrollableElement = styled.div<{
  marginBetweenItems: number;
  blockScroll: boolean;
}>(
  ({ marginBetweenItems, blockScroll }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: ${marginBetweenItems}px;
    overflow-y: hidden;
    overflow-x: ${blockScroll ? "hidden" : "unset"};
    scrollbar-width: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &:focus {
      outline: none;
    }
  `
);
