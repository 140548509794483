import React, {
  useCallback,
  useImperativeHandle,
  useState,
  useRef,
  RefObject,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  createRef
} from "react";
import { useOnClickOutside } from "@diana-ui/hooks";
import type { Direction } from "@diana-ui/portal";
import * as PopoverStyles from "./Popover.style";

export interface IProps {
  centered?: boolean;
  overlayParent?: boolean;
  direction?: Direction;
  forceDirection?: boolean;
  disabled?: boolean;
  dismissOnClick?: boolean;
  renderHeader?: (visible: boolean) => React.ReactNode;
  showOnHover?: boolean;
  useParentWidth?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  showOverlay?: boolean;
  zIndex?: number;
}

export type IPopoverProps = IProps & Omit<JSX.IntrinsicElements["div"], keyof IProps>;

export interface IPopoverRef {
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePopoverRef = (
  wrappedRef: ((instance: IPopoverRef) => void) | RefObject<IPopoverRef> | null | undefined
) => {
  const ref = useRef<IPopoverRef>(null);

  useImperativeHandle<IPopoverRef, IPopoverRef>(wrappedRef || createRef(), () => ({
    show: () => ref.current?.show(),
    hide: () => ref.current?.hide(),
    toggle: () => ref.current?.toggle()
  }));
  return ref;
};

export const Popover: ForwardRefRenderFunction<IPopoverRef, IPopoverProps> = (
  {
    className,
    centered = false,
    overlayParent = false,
    children,
    direction = "bottom",
    forceDirection = false,
    disabled = false,
    dismissOnClick = true,
    renderHeader,
    showOnHover = false,
    useParentWidth = false,
    onShow,
    onHide,
    showOverlay = false,
    zIndex
  },
  ref
) => {
  if (!ref) {
    // eslint-disable-next-line no-param-reassign
    ref = createRef();
  }

  const [newDirection, setNewDirection] = useState<Direction>(direction);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [visible, _setVisible] = useState(false);
  const setVisible = useCallback(
    (newVisible: boolean) => {
      if (newVisible === visible) {
        return;
      }
      const handler = newVisible ? onShow : onHide;
      if (handler) {
        handler();
      }
      _setVisible(newVisible);
    },
    [onHide, onShow, visible]
  );
  const [anchorHover, setAnchorHover] = useState(false);
  const [contentHover, setContentHover] = useState(false);

  useEffect(() => {
    if (showOnHover) {
      let timeout: NodeJS.Timeout | undefined;
      if (!anchorHover && !contentHover) {
        timeout = setTimeout(() => {
          setVisible(false);
        });
      } else if (!disabled) {
        setVisible(true);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [anchorHover, contentHover, setVisible, disabled, showOnHover]);

  const divRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside([divRef, portalRef], () => dismissOnClick && setVisible(false));
  const toggleVisible = useCallback(() => setVisible(!visible), [visible, setVisible]);
  if (Object.keys(ref || {}).length === 0) {
    // eslint-disable-next-line no-param-reassign
    ref = createRef();
  }
  useImperativeHandle<IPopoverRef, IPopoverRef>(ref, () => ({
    show: () => setVisible(true),
    hide: () => setVisible(false),
    toggle: () => toggleVisible()
  }));

  const handleClick = showOnHover
    ? undefined
    : () => {
        if (!disabled) {
          toggleVisible();
        }
      };

  const handleMouseEnter = showOnHover
    ? () => {
        setAnchorHover(true);
      }
    : undefined;

  const handleMouseLeaveAnchor = showOnHover
    ? () => {
        setAnchorHover(false);
      }
    : undefined;

  const handleMouseEnterContent = showOnHover
    ? () => {
        setContentHover(true);
      }
    : undefined;

  const handleMouseLeaveContent = showOnHover
    ? () => {
        setContentHover(false);
      }
    : undefined;

  return (
    <PopoverStyles.Container
      className={[
        className,
        disabled ? "disabled" : "",
        !showOnHover ? "show-on-hover" : "",
        newDirection
      ].join(" ")}
      ref={divRef}
    >
      {showOverlay && visible && (
        <PopoverStyles.Overlay onClick={() => dismissOnClick && setVisible(false)} />
      )}

      <PopoverStyles.HeaderWrapper
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeaveAnchor}
        className={newDirection}
      >
        {renderHeader?.(visible)}
      </PopoverStyles.HeaderWrapper>
      {visible && (
        <PopoverStyles.StyledPortal
          className={className}
          centered={centered}
          overlayParent={overlayParent}
          direction={direction}
          forceDirection={forceDirection}
          setNewDirection={setNewDirection}
          parentRef={divRef}
          useParentWidth={useParentWidth}
          zIndex={zIndex}
        >
          <PopoverStyles.Content
            onMouseEnter={handleMouseEnterContent}
            onMouseLeave={handleMouseLeaveContent}
          >
            <PopoverStyles.StyledPopover
              className={[
                disabled ? "disabled" : "",
                showOnHover ? "show-on-hover" : "",
                newDirection
              ].join(" ")}
              ref={portalRef}
            >
              {children}
            </PopoverStyles.StyledPopover>
          </PopoverStyles.Content>
        </PopoverStyles.StyledPortal>
      )}
    </PopoverStyles.Container>
  );
};

Popover.displayName = "Popover";

export { PopoverStyles };

export default forwardRef(Popover);
