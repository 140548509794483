import styled, { css } from "styled-components";

export const Overlay = styled.div(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${theme.colors.background.overlay};
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
  `
);

export const StyledSidebar = styled.div(
  () => css`
    height: 100%;
    z-index: 2;
    &.animate {
      transition: 0.3s all;
    }
    &.side {
      overflow: hidden;
      width: 0;
    }
    &.side.open {
      overflow: initial;
      width: 100%;
    }
    &.over {
      position: absolute;
      top: 0;
      transform: translateX(-100%);
    }
    &.over.right {
      right: 0;
      transform: translateX(100%);
    }
    &.over.open {
      transform: translateX(0);
    }
    &.over.right.open {
      right: 0;
    }
  `
);
