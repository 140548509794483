import { IPopoverRef } from "@diana-ui/popover";
import React, { createRef, forwardRef, useMemo } from "react";
import * as BaseMultipleDropdownStyles from "./BaseMultipleDropdown.style";
import { IItem, ISingleProps } from "./Dropdown";

export interface IMultipleProps<T extends IItem> extends Omit<ISingleProps<T>, "renderItem"> {
  onItemsSelected: (items: T[]) => void;
  selectedItems: T[];
  selectAllText?: string;
  selectAllItem?: (checked: boolean) => React.ReactNode;
  onClose?: () => void;
  renderAllSelectedHeader?: (visible: boolean, isAllButtonChecked: boolean) => JSX.Element;
}

export interface IBaseMultipleDropdownOptions {
  onItemClicked: (item: IItem, selectedItems: IItem[]) => void;
  onAllButtonClicked: () => void;
  isAllButtonChecked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomPopoverComponent?: any;
}

export interface IMultipleRenderItem<T extends IItem> {
  renderItem?: (
    item: T,
    selected: boolean,
    isAllSelected: boolean,
    index?: number
  ) => React.ReactNode;
}

export type IBaseMultipleDropdownProps<T extends IItem> = IMultipleProps<IItem> &
  IBaseMultipleDropdownOptions &
  IMultipleRenderItem<T>;

const BaseMultipleDropdown = forwardRef<IPopoverRef, IBaseMultipleDropdownProps<IItem>>(
  (props, ref) => {
    const {
      CustomPopoverComponent,
      className,
      disabled,
      items,
      selectedItems,
      selectAllText,
      selectAllItem,
      label,
      placeholder,
      text,
      renderItem,
      renderHeader,
      isAllButtonChecked,
      onItemClicked,
      onAllButtonClicked,
      onClose,
      renderAllSelectedHeader
    } = props;

    if (!ref) {
      // eslint-disable-next-line no-param-reassign
      ref = createRef();
    }

    const renderFinalHeader = useMemo(
      () =>
        renderHeader ||
        (() => (
          <BaseMultipleDropdownStyles.StyledDropdownheader
            text={
              text ??
              (selectedItems.length ? selectedItems.map(i => i.text).join(", ") : placeholder)
            }
          />
        )),
      [renderHeader, text, selectedItems, placeholder]
    );

    return (
      <BaseMultipleDropdownStyles.BaseMultipleDropdownWrapper className={className}>
        {label && (
          <BaseMultipleDropdownStyles.StyledLabel>{label}</BaseMultipleDropdownStyles.StyledLabel>
        )}
        <BaseMultipleDropdownStyles.StyledPopover
          as={CustomPopoverComponent}
          {...props}
          ref={ref}
          disabled={disabled || items.length === 0}
          renderHeader={(isOpen: boolean) =>
            isAllButtonChecked && renderAllSelectedHeader
              ? renderAllSelectedHeader(isOpen, isAllButtonChecked)
              : renderFinalHeader(isOpen)
          }
          useParentWidth
          onHide={onClose}
        >
          <BaseMultipleDropdownStyles.StyledUl>
            <BaseMultipleDropdownStyles.StyledAllLi
              onClick={onAllButtonClicked}
              role="presentation"
            >
              {selectAllItem?.(isAllButtonChecked) ?? selectAllText}
            </BaseMultipleDropdownStyles.StyledAllLi>
            {items.map((item: IItem, index: number) => (
              <BaseMultipleDropdownStyles.StyledLi
                className={[
                  selectedItems?.find(i => i.id === item.id) && "selected",
                  item.disabled ? "disabled" : ""
                ]
                  .filter(Boolean)
                  .join(" ")}
                key={item.id}
                onClick={() => onItemClicked(item, selectedItems)}
                role="presentation"
                title={item.text}
              >
                {renderItem?.(
                  item,
                  selectedItems.find(i => i.id === item.id) !== undefined,
                  isAllButtonChecked,
                  index
                ) ?? (
                  <BaseMultipleDropdownStyles.StyledItemText>
                    {item.text}
                  </BaseMultipleDropdownStyles.StyledItemText>
                )}
              </BaseMultipleDropdownStyles.StyledLi>
            ))}
          </BaseMultipleDropdownStyles.StyledUl>
        </BaseMultipleDropdownStyles.StyledPopover>
      </BaseMultipleDropdownStyles.BaseMultipleDropdownWrapper>
    );
  }
);

export { BaseMultipleDropdownStyles };

export default BaseMultipleDropdown;
