import React, { useCallback, useEffect, useState } from "react";
import type { ICarouselProps } from "./Carousel";
import * as BulletPagesCarouselStyles from "./BulletPagesCarousel.style";

export interface IBulletPagesCarouselProps extends ICarouselProps {
  /**
   * Number of bullets to appear on the carousel's footer
   * * Default value: 5
   */
  numberOfBullets?: number;
}

const BulletPagesCarousel: React.FC<IBulletPagesCarouselProps> = ({
  numberOfBullets = 5,
  ...props
}) => {
  const customScrollableElementRef = React.useRef<HTMLDivElement>();
  const [childrenSizeValue, setChildrenSize] = useState(0);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const calculatePageNumber = useCallback(() => {
    const element = customScrollableElementRef?.current;
    let newPageNumber = 0;

    if (element) {
      const currentScroll = element?.scrollLeft || 0;
      const clientWidth = element?.clientWidth || 0;

      const totalWidth = childrenSizeValue
        ? props.children.length * childrenSizeValue -
          (props.marginBetweenItems || 0)
        : element?.scrollWidth || 0;

      if (currentScroll !== 0) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        if (currentScroll > totalWidth - (clientWidth + 2)) {
          newPageNumber = numberOfBullets - 1;
        } else {
          const pages = numberOfBullets - 2;
          const scrollPerPage = totalWidth / pages;
          newPageNumber = Array(pages)
            .fill(scrollPerPage)
            .findIndex((val, index) => currentScroll < val * index);
          if (newPageNumber === -1) {
            newPageNumber = pages;
          }
        }
      }
    }
    return newPageNumber;
  }, [
    childrenSizeValue,
    numberOfBullets,
    props.children.length,
    props.marginBetweenItems
  ]);

  useEffect(() => {
    if (customScrollableElementRef?.current) {
      if (numberOfBullets === 1) {
        setPageNumber(0);
        return;
      }

      customScrollableElementRef.current.addEventListener("scroll", () =>
        setPageNumber(calculatePageNumber())
      );
    }
  }, [calculatePageNumber, customScrollableElementRef, numberOfBullets]);

  const footer = useCallback(
    ({ childrenSize }) => {
      if (!childrenSize) {
        setChildrenSize(childrenSize);
      }

      return (
        <BulletPagesCarouselStyles.CarouselFooterWrapper>
          {Array(numberOfBullets)
            .fill(null)
            .map((__, index) => (
              <BulletPagesCarouselStyles.Circle
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={pageNumber === index ? "selected" : ""}
              />
            ))}
        </BulletPagesCarouselStyles.CarouselFooterWrapper>
      );
    },
    [numberOfBullets, pageNumber]
  );

  return (
    <BulletPagesCarouselStyles.StyledCarousel
      {...props}
      footer={footer}
      customScrollableElementRef={customScrollableElementRef}
    />
  );
};

BulletPagesCarousel.displayName = "BulletPagesCarousel";

export { BulletPagesCarouselStyles };

export default BulletPagesCarousel;
