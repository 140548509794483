import styled, { css } from "styled-components";
import Carousel from "./Carousel";

export const StyledCarousel = styled(Carousel)``;

export const CarouselFooterWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
  `
);

export const Circle = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.grey.grey50};
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin: ${theme.spaceUnit.xxs};

    &.selected {
      background-color: ${theme.colors.grey.grey100};
    }
  `
);
