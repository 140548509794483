import styled, { css } from "styled-components";
import {
  Carousel as BaseCarousel,
  CarouselStyles as BaseCarouselStyles
} from "@diana-ui/carousel";
import type { ICarouselProps } from "@diana-ui/carousel";

const Carousel = styled(BaseCarousel)(
  ({ showScrollArrows }) => css`
    display: grid;
    grid-auto-flow: row;

    ${BaseCarouselStyles.Body} {
      height: auto;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr;

      ${showScrollArrows &&
      css`
        grid-template-columns: 38px repeat(1, minmax(0, 1fr)) 38px;
      `}
    }

    ${BaseCarouselStyles.ScrollableElement} {
      width: 100%;
      user-select: none;
    }

    ${BaseCarouselStyles.ArrowsContainerRight}, ${BaseCarouselStyles.ArrowsContainerLeft} {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  `
);

Carousel.displayName = "Carousel";

export { BaseCarouselStyles };

export type { ICarouselProps };

export default Carousel;
